.zoomer-container .zoom-in-selector {
    position: fixed;
    right: 60px;
    bottom: 20px;
    opacity: 1;
    z-index: 1;
}
.zoomer-container .zoom-out-selector {
    position: fixed;
    right: 15px;
    bottom: 20px;
    opacity: 1;
    z-index: 1;
}

.dragger-picker {
    cursor: pointer;
}

.rounded-50-percent {
    border-radius: 50%;
}

/* patch - to disable automatically appending iframe */
iframe {
    z-index: -11111 !important;
}

/* css for text wireframe element's foreign object */
.foreign-text-container-base {
    /* border-radius: 5px; */
    background: transparent;
    /* border: 2px solid #ccc; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
