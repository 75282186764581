body {
    margin: 0;
    font-family: 'Public Sans', sans-serif;

    /* font-weight: 700; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#main-two-root svg {
    /* background-image: url('./assets/ooorganize.svg') !important; */
    /* background-repeat: no-repeat; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    text-align: center;
}
#main-two-root {
    z-index: -9;
}
#main-two-root svg {
    background: #e5e5e5;
    z-index: -1;
}

/* #floating-toolbar {
//   position: absolute;
//   width: 340px;
//   height: 40px;
//   background: #091e42;
//   visibility: hidden;
//   border-radius: 10px;
//   z-index: 99999;
//   cursor: pointer;
//   outline: none;
// }
*/

#selector-rect {
    z-index: -1;
}
/* 
#rsz-rect {
    position: absolute;
    background: transparent;
    width: 100px;
    height: 100px;
    border: 1px solid #000;
    opacity: 0;
} */

text
/*
 g
 path */ {
    cursor: pointer !important;
    font-family: sans-serif;
}

g {
    z-index: 1;
}

button:focus {
    outline: none !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /* background-color: #282c34; */
    min-height: 10vh;
    /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: #282c34;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.temp-input-area-btn {
    background: transparent;
    border: none;
    color: #fff;
    /* font-family: 'Noto Sans JP'; */
    font-weight: 600;
    font-size: 16px;
    resize: none;
    outline: none !important;
}

.temp-input-area {
    background: transparent;
    border: none;
    color: #000;
    /* font-family: 'Noto Sans JP'; */
    font-weight: 300;
    font-size: 16px;
    resize: none;
    outline: none !important;
    line-height: 1.5rem;
}

.temp-textarea {
    background: transparent;
    border: none;
    color: #000;
    /* font-family: 'Noto Sans JP'; */
    font-weight: 600;
    font-size: 16px;
    resize: none;
    outline: none !important;
    line-height: 1.5rem;
    overflow-y: hidden;
}

/* Sidebar css */

.sidebar-container {
    z-index: 1;
    /* width: 22%; */
    -ms-flex: 0 100px;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -ms-box-flex: 0;
    box-flex: 0;
    position: fixed;
    top: 5px;
    /* height: 10%; */
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
}

/* @keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.2);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
} */
/* 
.sidebar-container .icon-elements:hover::after {
    content: 'Elements';
    width: 90px;
    position: absolute;
    top: 4px;
    left: 54px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
    font-size: 0.9rem;
}

.sidebar-container .icon-elements:hover::before {
    content: ' ';
    position: absolute;
    top: 14px;
    left: 51px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
}

.sidebar-container .icon-shape:hover::after {
    width: 90px;
    position: absolute;
    top: 4px;
    left: 54px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
    font-size: 0.9rem;
    content: 'Shapes';
}

.sidebar-container .icon-shape:hover::before {
    content: ' ';
    position: absolute;
    top: 14px;
    left: 51px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
}

.sidebar-container .icon-frame:hover::after {
    width: 90px;
    position: absolute;
    top: 4px;
    left: 54px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
    font-size: 0.9rem;
    content: 'Frames';
}
.sidebar-container .icon-frame:hover::before {
    content: ' ';
    position: absolute;
    top: 14px;
    left: 51px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
}

.sidebar-container .icon-text:hover::after {
    width: 90px;
    position: absolute;
    top: 4px;
    left: 54px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
    font-size: 0.9rem;
    content: 'Add Text';
}
.sidebar-container .icon-text:hover::before {
    content: ' ';
    position: absolute;
    top: 14px;
    left: 51px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
}

.sidebar-container .icon-pencil:hover::after {
    width: 90px;
    position: absolute;
    top: 4px;
    left: 54px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
    font-size: 0.9rem;
    content: 'Draw';
}
.sidebar-container .icon-pencil:hover::before {
    content: ' ';
    position: absolute;
    top: 14px;
    left: 51px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
}

.sidebar-container .icon-selector:hover::after {
    width: 90px;
    position: absolute;
    top: 4px;
    left: 54px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
    font-size: 0.9rem;
    content: 'Elements';
}
.sidebar-container .icon-selector:hover::before {
    content: ' ';
    position: absolute;
    top: 14px;
    left: 51px;
    background: #0747a6;
    padding: 4px 5px;
    color: #fff;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    z-index: 1;
} */

/* .secondary-sidebar-content::before {
//     content: ' ';
//     position: absolute;
//     top: 9px;
//     left: -5px;
//     background: #fff;
//     transform: rotate(45deg);
//     width: 10px;
//     height: 10px;
//     -webkit-transition: all 1s;
//     -moz-transition: all 1s;
//     -o-transition: all 1s;
//     transition: all 1s;
// }
*/

.secondary-sidebar-shapes {
    position: fixed;
    top: 45px;
    left: 21px;
}

.caret-icon::before {
    content: '';
    position: absolute;
    background-color: #0052cc;
    width: 3px;
    height: 9px;
    transform: translate(2px, 0) rotate(45deg);
}
.caret-icon::after {
    content: '';
    position: absolute;
    background-color: #0052cc;
    width: 3px;
    height: 9px;
    transform: translate(2px, 0) rotate(-45deg);
}

/** COMMON CSS CLASSES **/
.transform-rotate-90 {
    transform: rotate(90deg);
}

.transform-rotate-45 {
    transform: rotate(45deg);
}

.transform-rotate-135 {
    transform: rotate(135deg);
}

.transform-rotate-180 {
    transform: rotate(180deg);
}

.border-radius-50 {
    border-radius: 50%;
}

.border-6 {
    border-width: 6px;
}

.h-max-10vh {
    max-height: 10vh;
}

.h-max-11vh {
    max-height: 11vh;
}

.w-90-per {
    width: 90%;
}

.h-90-per {
    height: 90%;
}

.w-80-per {
    width: 80%;
}

.h-80-per {
    height: 80%;
}

.w-70-per {
    width: 70%;
}

.h-70-per {
    height: 70%;
}

.w-60-per {
    width: 60%;
}

.h-60-per {
    height: 60%;
}

.w-50-per {
    width: 50%;
}

.h-50-per {
    height: 50%;
}
